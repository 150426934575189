<template>
    <div class="home">
        <a-descriptions bordered size="small" :column='2' v-if="res !== null">
            <template #extra>
                <a-space direction="vertical" style="margin: 0 20px;">
                </a-space>
                <a-form layout="inline">
                    <a-form-item>
                        <a-range-picker v-model:value="value1" :show-time="{ format: 'HH:mm' }" :locale="locale"
                            format="YYYY-MM-DD HH:mm" @change="pickerChangeFun" />
                    </a-form-item>
                    <a-form-item>
                        <a-radio-group v-model:value="sort" button-style="solid">
                            <a-radio-button value="asc">升序</a-radio-button>
                            <a-radio-button value="desc">降序</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <!-- <a-input v-model:value="packageNo" placeholder="请输入订单号" /> -->
                        <a-select ref="select" v-model:value="c_type" style="width: 100px">
                            <a-select-option value="">全部</a-select-option>
                            <a-select-option value="D">支出</a-select-option>
                            <a-select-option value="C">收入</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="finishFun">搜索</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="finishFun(2)">
                            <DownloadOutlined />导出
                        </a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="resetFun">
                            <ReloadOutlined />
                        </a-button>
                    </a-form-item>

                </a-form>
            </template>

            <a-descriptions-item label="总收入">{{ res.all_sr }}</a-descriptions-item>
            <a-descriptions-item label="总支出">{{ res.all_zc }}</a-descriptions-item>
            <a-descriptions-item label="手续费">{{ res.all_rate }}</a-descriptions-item>
            <a-descriptions-item label="收入笔数">{{ res.sr_count }}</a-descriptions-item>
            <a-descriptions-item label="支出笔数">{{ res.zc_count }}</a-descriptions-item>
        </a-descriptions>

        <div class="home_box">
            <a-table class="components-table-demo-nested" :columns="columns" :data-source="list" :scroll="{ y: 350 }"
                :pagination="false">
                <!-- <template #headerCell="{ column }">
                    <template v-if="column.key === 'Controls'">
                        <button>查询状态</button>
                    </template>
                </template> -->

                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'Controls'">
                        <a-button type="primary" @click="StatusQueryFun(record)">查询状态</a-button>
                    </template>
                </template>
            </a-table>
            <a-pagination style="margin-top: 20px;" v-model:current="current" simple :total="total"
                @change="paginationChange" :pageSize="10" :hideOnSinglePage="false" />
        </div>




        <a-modal v-model:open="open" width="800px" title="查询状态" @ok="handleOk" :footer="null">
            <a-table :columns="columnss" :scroll="{ y: 300 }" :pagination='false' :data-source="dataSource" />
        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { api_cw_get_finance_list, api_cw_balance_inquiry, api_cw_pay_check, api_cw_select_trade } from '@/api/home';
import { message, type TableColumnsType } from 'ant-design-vue';

import { ReloadOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import type { Dayjs } from 'dayjs';
// 局部引入
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

type RangeValue = [Dayjs, Dayjs];
const value1 = ref<RangeValue | null>();
let current = ref<number>(1)
let user: any = localStorage.getItem('user') || sessionStorage.getItem('user');
let res = ref<any>(null)
user = JSON.parse(user);

let sort = ref<string>('desc');
let c_type = ref<string>('');
let date = ref<string[]>([]);

watch(value1, (newValue, oldValue) => {
    if (newValue === null) {
        list.value = [];
        current.value = 1;
        total.value = 1;
    }
})

let packageNo = ref('');

onMounted(() => {
    console.log('组件加载');
    // api_cw_balance_inquiryFun();
    api_cw_select_tradeFun();
    finishFun('');
});




const resetFun = () => {
    current.value = 1;
    sort.value = 'desc';
    c_type.value = '';
    date.value = [];
    // pickerChangeFun(null);
    value1.value = null;
    finishFun('');
}




const dateFun = (timestamp: number): string => {
    console.log(timestamp);
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var Hours = date.getHours();
    var Minutes = date.getMinutes()
    return `${year}${month}${day}${Hours}${Minutes}00`
}



const TheInverseDateFun = (str: string): string => {
    let year = str.slice(0, 4) + '年';
    let month = str.slice(4, 6) + '月';
    let day = str.slice(6, 8) + '日';
    let hour = str.slice(8, 10) + '时';
    let minute = str.slice(10, 12) + '分';
    let second = str.slice(12, 14) + '秒';
    return year + month + day + hour + minute + second
}



const api_cw_select_tradeFun = async () => {


}

const handleChange = (e: any) => {
    console.log(e);
}
const focus = (e: any) => {
    console.log(e);
}
const paginationChange = (e: number) => {
    current.value = e;
    finishFun('1');
}



let usableBalance = ref(0);
let balance = ref(0);
let lastDayBalance = ref(0);

const open = ref<boolean>(false);


const showModal = () => {
    open.value = true;
};

const handleOk = (e: MouseEvent) => {
    console.log(e);
    open.value = false;
};













const api_cw_balance_inquiryFun = async () => {
    let data: any = await api_cw_balance_inquiry(user.payAccNo, user.openid);
    usableBalance.value = data.usableBalance
    balance.value = data.balance
    lastDayBalance.value = data.lastDayBalance
    console.log(data);

}

const columns: TableColumnsType = [
    { title: '转账账号', width: 100, dataIndex: 'recAccNo' },
    { title: '开户名', width: 100, dataIndex: 'recAccName' },
    // { title: '银行名称', width: 100, dataIndex: 'recBankName' },
    { title: '转账金额', width: 100, dataIndex: 'amount' },
    { title: '状态', width: 100, dataIndex: 'drcType' },
    { title: '订单号', width: 100, dataIndex: 'retNo' },
    { title: '操作时间', width: 100, dataIndex: 'tranTime' },
    // { title: '操作', width: 100, dataIndex: 'Controls' }
];

const columnss: TableColumnsType = [
    { title: '转账账号', width: "30%", dataIndex: 'recAccNo' },
    { title: '转账金额', width: "15%", dataIndex: 'amount' },
    { title: '转账状态', width: "30%", dataIndex: 'desc' },
]

let list: any = ref([]);
let dataSource: any = []

let total: any = ref(1);
let arr: any = ref([])
const finishFun = async (value: any = '') => {
    console.log(value);
    if (value !== '1') current.value = 1
    arr.value = [];
    let up = null
    if (value === 2) {
        arr.value = []
        up = {
            openid: user.openid,
            export: 1,
            page: current.value,
            sort: sort.value,  //排序  asc   desc
            beginDate: date.value[0], //开始时间
            endDate: date.value[1],   //结束时间
            c_type: c_type.value //C  收入   D支出   不传  就是全部  
        }
        const data: any = await api_cw_select_trade(up);
        console.log(data);
        data.list.forEach((item: any) => {
            console.log(item);
            let obj: any = {}
            for (let key in item) {
                console.log(key);

                switch (true) {
                    case key === 'amount':
                        obj['转账金额'] = item['amount']
                        break;
                    case key === 'recAccName':
                        obj['开户名'] = item['recAccName']
                        break;
                    case key === 'recAccNo':
                        obj['转账账号'] = item['recAccNo']
                        break;
                    case key === 'retNo':
                        obj['订单号'] = item['retNo']
                        break;
                    case key === 'drcType':
                        obj['状态'] = item['drcType']
                        break;
                    case key === 'tranTime':
                        item.tranTime = TheInverseDateFun(item.tranTime);
                        obj['操作时间'] = item['tranTime']
                        break;
                }
            }
            arr.push(obj)
        })
        console.log(arr);
        const ws = XLSX.utils.json_to_sheet(arr);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        function s2ab(s: any) {

            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            console.log(view);
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${data.dc_time}导出明细.xlsx`);
    } else {
        list.value = []
        up = {
            openid: user.openid,
            page: current.value,
            sort: sort.value,  //排序  asc   desc
            beginDate: date.value[0], //开始时间
            endDate: date.value[1],   //结束时间
            c_type: c_type.value //C  收入   D支出   不传  就是全部  
        }
        const data: any = await api_cw_select_trade(up);
        console.log(data);
        res.value = data;
        if (data.error !== 0) {
            message.error('暂无数据！！！')
            return
        }
        total.value = data.total * 1;
        data.list.forEach((item: any) => {
            item.tranTime = TheInverseDateFun(item.tranTime);
            list.value.push(item);
        });
    }

}


let pickerChangeFun = (e: any) => {
    console.log(e);

    if (e === null) return
    if (date.value.length === 2) {
        date.value = []
    }
    for (let index = 0; index < e.length; index++) {
        const element = e[index].$d;

        let str: string = dateFun(Date.parse(element))
        date.value.push(str);
    }
}

const StatusQueryFun = async (item: any) => {
    console.log(item);
    dataSource.value = [];

    let up = {
        openid: user.openid,
        queryPacNo: item.packageNo
    }
    console.log(up);

    let data: any = await api_cw_pay_check(up);
    if (data.header.code === '000000') {
        open.value = true;
        data.body.details.forEach((item: any) => {

            let obj = {
                recAccNo: item.recAccNo,
                amount: item.amount / 100,
                desc: item.desc
            }
            dataSource.value.push(obj);
        });
        console.log(dataSource.value);
    }






}


</script>

<style lang="less" scoped>
.home {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .home_box {
        width: 100%;
        flex: 1;
        padding: 20px 0;
    }
}
</style>