<!-- <template>
    <div class="balance_enquiry">
        <a-input-search v-model:value="value" placeholder="请输入银行卡卡号" enter-button @search="onSearch" />


        <a-modal v-model:open="open" title="银行卡余额" @ok="handleOk">
            <a-descriptions :column="1">
                <a-descriptions-item label="当前余额">{{ balance }}</a-descriptions-item>
            </a-descriptions>
        </a-modal>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { api_cw_balance_inquiry } from '@/api/home'
import store from '@/store';
export default {
    name: 'BalanceEnquiry',
    setup() {
        let user: any = localStorage.getItem('user') || sessionStorage.getItem('user');
        user = JSON.parse(user);

        let balance: any = ref(0);


        const value = ref<string>('');

        let open = ref<boolean>(false)

        const onSearch = async (searchValue: string) => {

            if (regFun(searchValue)) {
                let data: any = await api_cw_balance_inquiry(searchValue, user.openid);
                if (data.error === 0) {
                    balance.value = data.usableBalance
                    open.value = true;
                }
            } else {
                message.error('请输入正确的银行卡卡号');
            }
        };

        function regFun(str: string = ''): boolean {

            var reg = /^\d+$/;

            return reg.test(str)
        }

        function handleOk() {
            open.value = false;
        }






        return {
            value,
            open,
            balance,
            user,
            onSearch,
            handleOk
        }
    }
}
</script>

<style lang="less" scoped>
.balance_enquiry {
    width: 100%;
    height: 100%;
    padding: 50px;
}
</style> -->



<template>
    <div class="home">

        <a-descriptions bordered size="small" :column='2'>
            <template #extra>
                <a-space direction="vertical" style="margin: 0 20px;">
                </a-space>
                <a-form layout="inline">
                    <a-form-item label="">
                        <!-- <a-range-picker v-model:value="value1"  @change="pickerChangeFun" /> -->
                        <a-range-picker v-model:value="value1" :show-time="{ format: 'HH:mm' }" :locale="locale"
                            format="YYYY-MM-DD HH:mm" @change="pickerChangeFun" />
                    </a-form-item>
                    <a-form-item label="">
                        <a-input v-model:value="packageNo" placeholder="请输入订单号" />
                    </a-form-item>
                    <a-form-item>
                        <a-select ref="select" v-model:value="status" style="width: 100px">
                            <a-select-option value="1">全部</a-select-option>
                            <a-select-option value="2">成功</a-select-option>
                            <a-select-option value="3">失败</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="finishFun">搜索</a-button>
                    </a-form-item>
                </a-form>
            </template>
        </a-descriptions>

        <div class="home_box">
            <a-table class="components-table-demo-nested" :columns="columns" :data-source="list" :scroll="{ y: 400 }"
                :pagination="false">
                <!-- <template #headerCell="{ column }">
                    <template v-if="column.key === 'Controls'">
                        <button>查询状态</button>
                    </template>
                </template> -->

                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'Controls'">
                        <a-button type="primary" @click="StatusQueryFun(record)">查询状态</a-button>
                    </template>
                </template>
            </a-table>
            <a-pagination style="margin-top: 20px;" v-model:current="current" simple :total="total"
                @change="paginationChange" :pageSize="10" :hideOnSinglePage="false" />
        </div>




        <a-modal v-model:open="open" width="800px" title="查询状态" @ok="handleOk" :footer="null">
            <a-table :columns="columnss" :scroll="{ y: 300 }" :pagination='false' :data-source="dataSource" />
        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { api_cw_get_finance_list, api_cw_balance_inquiry, api_cw_pay_check } from '@/api/home';
import { message, type TableColumnsType } from 'ant-design-vue';

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import type { Dayjs } from 'dayjs';

console.log(locale);

import router from "@/router";



type RangeValue = [Dayjs, Dayjs];
const value1 = ref<RangeValue>();
let current = ref<number>(1)
let user: any = localStorage.getItem('user') || sessionStorage.getItem('user');
user = JSON.parse(user);

let status = ref<string>('')


watch(value1, (newValue, oldValue) => {
    if (newValue === null) {
        list.value = [];
        current.value = 1;
        total.value = 1;
    }
})

let packageNo = ref('');

onMounted(() => {
    console.log('组件加载');
    // api_cw_balance_inquiryFun();
    finishFun('');
});

const handleChange = (e: any) => {
    console.log(e);
}
const focus = (e: any) => {
    console.log(e);
}
const paginationChange = (e: number) => {
    current.value = e;
    finishFun('');
}


let date = ref<number[]>([]);

let usableBalance = ref(0);
let balance = ref(0);
let lastDayBalance = ref(0);

const open = ref<boolean>(false);


const showModal = () => {
    open.value = true;
};

const handleOk = (e: MouseEvent) => {
    console.log(e);
    open.value = false;
};













const api_cw_balance_inquiryFun = async () => {
    let data: any = await api_cw_balance_inquiry(user.payAccNo, user.openid);
    usableBalance.value = data.usableBalance
    balance.value = data.balance
    lastDayBalance.value = data.lastDayBalance
    console.log(data);

}

const columns: TableColumnsType = [
    { title: '转账账号', width: 100, dataIndex: 'recAccNo' },
    { title: '开户名', width: 100, dataIndex: 'recAccName' },
    { title: '银行名称', width: 100, dataIndex: 'recBankName' },
    { title: '转账金额', width: 100, dataIndex: 'amount' },
    { title: '订单号', width: 100, dataIndex: 'packageNo' },
    { title: '操作时间', width: 100, dataIndex: 'time' },
    { title: '状态', width: 100, dataIndex: 'message' },
    { title: '操作', width: 100, dataIndex: 'Controls' }
];

const columnss: TableColumnsType = [
    { title: '转账账号', width: "30%", dataIndex: 'recAccNo' },
    { title: '转账金额', width: "15%", dataIndex: 'amount' },
    { title: '转账状态', width: "30%", dataIndex: 'desc' },
]

let list: any = ref([]);
let dataSource: any = ref([]);

let total: any = ref(1)
const finishFun = async (value: any) => {
    list.value = [];
    let up = {
        accNo: user.payAccNo,  //查询银行账号
        pageNo: current.value, //分页 （从1开始）
        beginDate: date.value[0] ? date.value[0] / 1000 : 0,  //开始时间
        endDate: date.value[1] ? date.value[1] / 1000 : 0, //结束时间
        openid: user.openid,
        status: status.value,
        packageNo: packageNo.value

    }
    let data: any = await api_cw_get_finance_list(up);
    if (data.error !== 0) {
        message.error('暂无数据！！！')
        return
    }
    total.value = data.total * 1;
    data.list.forEach((item: any) => {
        list.value.push(item);
    });
    packageNo.value = '';
}


let pickerChangeFun = (e: any) => {
    if (e === null) return
    if (date.value.length === 2) {
        date.value = []
    }
    for (let index = 0; index < e.length; index++) {
        const element = e[index].$d;
        date.value.push(Date.parse(element));
    }
}

const StatusQueryFun = async (item: any) => {
    console.log(item);
    dataSource.value = [];

    let up = {
        openid: user.openid,
        queryPacNo: item.packageNo
    }
    console.log(up);

    let data: any = await api_cw_pay_check(up);
    if (data.header.code === '000000') {
        open.value = true;
        data.body.details.forEach((item: any) => {

            let obj = {
                recAccNo: item.recAccNo,
                amount: item.amount / 100,
                desc: item.desc
            }
            dataSource.value.push(obj);
        });
        console.log(dataSource.value);
    }






}


</script>

<style lang="less" scoped>
.home {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .home_box {
        width: 100%;
        flex: 1;
        padding: 20px 0;
    }
}
</style>