<template>
    <div class="home">
        <a-form layout="inline">
            <a-form-item label="">
                <a-range-picker v-model:value="value1" :show-time="{ format: 'HH:mm' }" :locale="locale"
                    format="YYYY-MM-DD HH:mm" @change="pickerChangeFun" />
            </a-form-item>
            <a-form-item>
                <a-select ref="select" v-model:value="status" style="width: 100px">
                    <a-select-option value="0">全部</a-select-option>
                    <a-select-option value="1">收入</a-select-option>
                    <a-select-option value="3">支出</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="finishFun">搜索</a-button>
            </a-form-item>
        </a-form>
        <div class="home_box">
            <a-table :columns="columns" :data-source="list" :scroll="{ y: '580px' }" @change="paginationChange"
                :pagination="{ total: total, current: current, pageSize: 10 }">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'Controls'">
                        <a-button style="width:100px;margin-bottom: 10px;" v-if="record.retNo !== ''" type="primary"
                            @click="ReceiptQueryFun(record)">下载回单</a-button>


                        <a-popconfirm title="是否确认操作?" ok-text="确认" cancel-text="取消" v-if="record.drcType !== '支出'"
                            @confirm="popconfirmConfirmFun(record)" @cancel="popconfirmCancelFun"
                            :disabled="record.status === '已处理'">
                            <a-button style="width:100px;" v-if="record.retNo !== ''" type="primary"
                                :style="record.status === '已处理' ? 'background-color:green' : 'background-color:red'">{{
                                    record.status === "已处理" ? "已处理" : '确认打款' }}</a-button>
                        </a-popconfirm>


                    </template>
                </template>
            </a-table>


        </div>

        <a-modal v-model:open="open" width="800px" title="查询状态" @ok="handleOk" :footer="null">
            <a-table :columns="columnss" :scroll="{ y: 300 }" :pagination='false' :data-source="dataSource" />
        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { api_cw_get_finance_list, api_cw_balance_inquiry, api_cw_pay_check, api_cw_hd_check, api_cw_get_balance_list, api_cw_dk_cl } from '@/api/home';
import { message, type TableColumnsType } from 'ant-design-vue';

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

import type { Dayjs } from 'dayjs';

import router from "@/router";

let date = ref<any>([]);


type RangeValue = [Dayjs, Dayjs];
const value1 = ref<RangeValue>();
let current = ref<number>(1)
let user: any = localStorage.getItem('user') || sessionStorage.getItem('user');
user = JSON.parse(user);

let status = ref<string>('0')


watch(value1, (newValue, oldValue) => {
    if (newValue === null) {
        list.value = [];
        current.value = 1;
        total.value = 1;
    }
})


const popconfirmConfirmFun = async (res: any) => {
    console.log(res.transSeqNb);
    let up = {
        openid: user.openid,
        transSeqNb: res.transSeqNb
    }
    let data = await api_cw_dk_cl(up);
    console.log(data);

}
const popconfirmCancelFun = async () => {
    message.error('已取消')
}


let packageNo = ref('');

onMounted(() => {
    console.log('组件加载');
    // api_cw_balance_inquiryFun();
    date.value[0] = dateFun(new Date().getTime() - 86400000, 0);
    date.value[1] = dateFun(new Date().getTime(), 1);


    finishFun('');




});

const handleChange = (e: any) => {
    console.log(e);
}
const focus = (e: any) => {
    console.log(e);
}
const paginationChange = (e: any) => {

    current.value = e.current;

    finishFun('');
}



let usableBalance = ref(0);
let balance = ref(0);
let lastDayBalance = ref(0);

const open = ref<boolean>(false);


const showModal = () => {
    open.value = true;
};

const handleOk = (e: MouseEvent) => {
    console.log(e);
    open.value = false;
};













const api_cw_balance_inquiryFun = async () => {
    let data: any = await api_cw_balance_inquiry(user.payAccNo, user.openid);
    usableBalance.value = data.usableBalance
    balance.value = data.balance
    lastDayBalance.value = data.lastDayBalance
    console.log(data);

}

// const columns: TableColumnsType = [
//     { title: '转账账号', width: 100, dataIndex: 'recAccNo' },
//     { title: '开户名', width: 100, dataIndex: 'recAccName' },
//     { title: '银行名称', width: 100, dataIndex: 'recBankName' },
//     { title: '转账金额', width: 100, dataIndex: 'amount' },
//     { title: '订单号', width: 100, dataIndex: 'packageNo' },
//     { title: '操作时间', width: 100, dataIndex: 'time' },
//     { title: '状态', width: 100, dataIndex: 'message' },
//     { title: '操作', width: 100, dataIndex: 'Controls' }
// ];


const ReceiptQueryFun = async (item: any) => {
    console.log(item);
    let up = {
        openid: user.openid,
        // accNo: item.recAccNo,
        accNo: user.payAccNo,
        retNo: item.retNo
    }
    let data: any = await api_cw_hd_check(up);
    if (data.error === 0) {
        base64ToPDF('data:application/pdf;base64,' + data.body.billFileContent, 'example.pdf')
    }
}





const columns = [
    {
        title: '交易金额',
        dataIndex: 'amount',
        // width: '15%',
    },
    {
        title: '状态',
        dataIndex: 'drcType',
        width: '100px'
    },
    {
        title: '对方账户名称',
        dataIndex: 'recAccName',
        // width: '15%',
    },
    {
        title: '对方账号',
        dataIndex: 'recAccNo',
        // width: '15%',
    },
    {
        title: '交易流水号',
        dataIndex: 'transSeqNb',
        width: '15%',
    },
    {
        title: '交易日期',
        dataIndex: 'tranDt',
        width: '140px',
    },
    {
        title: '附言',
        dataIndex: 'memo'
    },
    {
        title: '操作',
        dataIndex: 'Controls',
        // width: '15%',
    }
];



const base64ToPDF = (base64String: string, pdfName: string) => {
    let binaryData = atob(base64String.split(',')[1]);
    let mimeString = base64String.split(',')[0].split(':')[1];
    let arrayBuffer = new ArrayBuffer(binaryData.length);
    let uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
        uintArray[i] = binaryData.charCodeAt(i);
    }
    let blob = new Blob([uintArray], { type: mimeString });
    let url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = pdfName || 'downloadedPDF.pdf';
    // 触发下载
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}








const columnss: TableColumnsType = [
    { title: '转账账号', width: "30%", dataIndex: 'recAccNo' },
    { title: '转账金额', width: "15%", dataIndex: 'amount' },
    { title: '转账状态', width: "30%", dataIndex: 'desc' },
]

let list: any = ref([]);
let dataSource: any = ref([]);

let total: any = ref(1);

const TheInverseDateFun = (str: string): string => {
    let year = str.slice(0, 4) + '年';
    let month = str.slice(4, 6) + '月';
    let day = str.slice(6, 8) + '日';
    let hour = str.slice(8, 10) + '时';
    let minute = str.slice(10, 12) + '分';
    let second = str.slice(12, 14) + '秒';
    return year + month + day + hour + minute + second
}


const finishFun = async (value: any) => {
    list.value = [];
    let up = {
        accNo: user.payAccNo,  //查询银行账号
        pageNo: current.value, //分页 （从1开始）
        beginDate: date.value[0],  //开始时间
        endDate: date.value[1], //结束时间
        openid: user.openid,
        type: status.value
    }
    console.log(up, '------------------');
    let data: any = await api_cw_get_balance_list(up);
    console.log(data);
    if (data) {
        // return
        if (data.header.code === "000000") {
            total.value = data.body.totalNum;
            data.body.details.forEach((item: any) => {
                let obj = {
                    amount: item.amount / 100,
                    recAccNo: item.recAccNo,
                    recAccName: item.recAccName,
                    transSeqNb: item.transSeqNb,
                    tranDt: TheInverseDateFun(item.tranTime),
                    retNo: item.retNo,
                    drcType: item.drcType === 'D' ? '支出' : '收入',
                    memo: item.memo,
                    status: item.status
                }
                list.value.push(obj)
            })
            // for (let index = 0; index < 40; index++) {
            //     const element = data.body.details[0];
            //     // console.log(element);
            //     list.value.push(element)

            // }
        } else {
            if (data.header.msg) message.error(data.header.msg);
        }
    }
}


let pickerChangeFun = (e: any) => {
    console.log(e);

    if (e === null) return
    if (date.value.length === 2) {
        date.value = []
    }
    for (let index = 0; index < e.length; index++) {
        const element = e[index].$d;
        console.log(element);
        let str = dateFun(Date.parse(element), 3)
        date.value.push(str);
    }
}

const StatusQueryFun = async (item: any) => {
    console.log(item);
    dataSource.value = [];

    let up = {
        openid: user.openid,
        queryPacNo: item.packageNo
    }
    console.log(up);

    let data: any = await api_cw_pay_check(up);
    if (data.header.code === '000000') {
        if (data.body.details.length !== 0) {
            open.value = true;
            data.body.details.forEach((item: any) => {

                let obj = {
                    recAccNo: item.recAccNo,
                    amount: item.amount / 100,
                    desc: item.desc
                }
                dataSource.value.push(obj);
            });
            console.log(dataSource.value);
        }
    }
}

const dateFun = (timestamp: number, index: number): string => {
    console.log(timestamp, index);

    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var Hours = date.getHours();
    var Minutes = date.getMinutes()
    return `${year}${month}${day}`
}

</script>

<style lang="less" scoped>
.home {
    width: 100%;
    // height: calc(100vh - 60px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .home_box {
        width: 100%;
        flex: 1;
        // padding: 20px 0;
        margin-top: 20px;
    }
}
</style>