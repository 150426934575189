import axios from 'axios';
import { message } from 'ant-design-vue';
import store from '@/store';
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
const request = (url: string, text: string, data: any, loading: boolean = true) => {
  const baseUrl = `https://cw.jibeimall.com/app/index.php?i=4&c=entry&m=ewei_shopv2&do=mobile&r=${url}&app=1`;
  if (loading) store.commit('SPINNING_FUN', true);
  let method = text.toUpperCase();
  // 添加请求拦截器
  // axios.interceptors.request.use((config) => {
  //   // 在发送请求之前做些什么
  //   return config;
  // }, function (error) {
  //   // 对请求错误做些什么
  // });

  // // 添加响应拦截器
  axios.interceptors.response.use((response) => {
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 对响应错误做点什么
  })
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: baseUrl,
      data: data,
      headers
    }).then(res => {
      if (res.data.error === 0) {
        if (res.data.message) message.success(res.data.message);
      } else {
        if (res.data.message) message.error(res.data.message);
      }
      if (res.data.header) {
        if (res.data.header.code === "000000") {
          if (res.data.header.msg) message.success(res.data.header.msg)
        } else if (res.data.header.code) {
          if (res.data.header.msg) message.error(res.data.header.msg)
        }
      }
      resolve(res.data); //成功
      store.commit('SPINNING_FUN', false);
    })
      .catch(err => {
        console.log('请求失败');
        reject(err); //失败
        store.commit('SPINNING_FUN', false);
      })
  })
}
export default request;
