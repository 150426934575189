<!-- <template>
    <div>
        
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less" scoped>
</style> -->

<template>
    <div>
        <p v-if="errorMessage">{{ errorMessage }}</p>
        <p v-if="latitude && longitude">
            纬度: {{ latitude }}，经度: {{ longitude }}
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            latitude: null,
            longitude: null,
            errorMessage: null,
        };
    },
    methods: {
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log(position);
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude;
                    },
                    (error) => {
                        this.errorMessage = error.message;
                    },
                    {
                        enableHighAccuracy: true, // 是否要求高精度的位置信息
                        timeout: 100000, // 请求的超时时间
                        maximumAge: 0, // 对获取的地理位置信息的缓存时间
                    }
                );
            } else {
                this.errorMessage = "Geolocation is not supported by this browser.";
            }
        },
    },
    mounted() {
        this.getCurrentLocation();
    },
};
</script>